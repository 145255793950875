import moment from "moment";
import { mainApi } from "../providers/api";
import { useState, useEffect } from "react";
import objectPath from "object-path";
import { ArrowLeft2, ArrowRight2, Refresh } from "iconsax-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Table from "./table";
import Loader from "../components/Loader";
import StackedBar from "./stacketbar";
import Swal from "sweetalert2";
import { useApp } from "../providers/app";

const gettotal = (arr) => {
  let ub_asap = 0,
    ub_drunk = 0,
    ub_inspection = 0,
    ub_insurance = 0,
    ub_location = "UB",
    ub_noasap = 0,
    ub_nodrunk = 0,
    ub_noinspection = 0,
    ub_noinsurance = 0,
    ub_nopenalty = 0,
    ub_notax = 0,
    ub_penalty = 0,
    ub_tax = 0,
    ub_limit = 0,
    ub_nolimit = 0,
    ub_violation = 0,
    ub_noviolation = 0,
    ub_total = 0,
    asap = 0,
    drunk = 0,
    inspection = 0,
    insurance = 0,
    location = "COUNTRY",
    noasap = 0,
    nodrunk = 0,
    noinspection = 0,
    noinsurance = 0,
    nopenalty = 0,
    notax = 0,
    penalty = 0,
    tax = 0,
    limit = 0,
    nolimit = 0,
    violation = 0,
    noviolation = 0,
    total = 0;
  arr.forEach((item) => {
    item?.map((row) => {
      if (row.location == "COUNTRY") {
        asap += row.asap;
        drunk += row.drunk;
        inspection += row.inspection;
        insurance += row.insurance;
        noasap += row.noasap;
        nodrunk += row.nodrunk;
        noinspection += row.noinspection;
        noinsurance += row.noinsurance;
        nopenalty += row.nopenalty;
        notax += row.notax;
        penalty += row.penalty;
        limit += row.limit;
        nolimit += row.nolimit;
        violation += row.violation;
        noviolation += row.noviolation;
        tax += row.tax;
        total += row.total;
      } else if (row.location == "UB") {
        ub_asap += row.asap;
        ub_drunk += row.drunk;
        ub_inspection += row.inspection;
        ub_insurance += row.insurance;
        ub_noasap += row.noasap;
        ub_nodrunk += row.nodrunk;
        ub_noinspection += row.noinspection;
        ub_noinsurance += row.noinsurance;
        ub_nopenalty += row.nopenalty;
        ub_notax += row.notax;
        ub_penalty += row.penalty;
        ub_tax += row.tax;
        ub_limit += row.limit;
        ub_nolimit += row.nolimit;
        ub_violation += row.violation;
        ub_noviolation += row.noviolation;
        ub_total += row.total;
      }
    });
  });

  return [
    {
      asap: ub_asap,
      drunk: ub_drunk,
      inspection: ub_inspection,
      insurance: ub_insurance,
      location: ub_location,
      noasap: ub_noasap,
      nodrunk: ub_nodrunk,
      noinspection: ub_noinspection,
      noinsurance: ub_noinsurance,
      nopenalty: ub_nopenalty,
      notax: ub_notax,
      penalty: ub_penalty,
      tax: ub_tax,
      limit: ub_limit,
      nolimit: ub_nolimit,
      violation: ub_violation,
      noviolation: ub_noviolation,
      total: ub_total,
    },
    {
      asap: asap,
      drunk: drunk,
      inspection: inspection,
      insurance: insurance,
      location: location,
      noasap: noasap,
      nodrunk: nodrunk,
      noinspection: noinspection,
      noinsurance: noinsurance,
      nopenalty: nopenalty,
      notax: notax,
      penalty: penalty,
      tax: tax,
      limit: limit,
      nolimit: nolimit,
      violation: violation,
      noviolation: noviolation,
      total: total,
    },
  ];
};
const checkDate = (date) => {
  if (date > new Date()) {
    Swal.fire(`Анхааруулга`, `Ирээдүйд огноо сонгох боломжгүй`, "error");
    return false;
  } else return true;
};
const Systems = ({ active }) => {
  const [date, setdate] = useState(new Date());
  const [data, setdata] = useState();
  const [loading, setloading] = useState(false);
  const [tab, settab] = useState("total");
  const { user } = useApp();
  const defaultFind =
    user?.role == "statistic" ? { $in: user?.grouplist } : null;
  console.log("🚀 ~ Violation ~ defaultFind:", defaultFind, user?.role);

  useEffect(() => {
    fetch();
  }, [date]);

  const fetch = async () => {
    setloading(true);
    const response = await mainApi({
      url: `/service/system`,
      auth: {
        username: "odt",
        password: "odt123456",
      },
      method: `POST`,
      data: {
        date: new Date(date),
        device: defaultFind,
      },
    });

    const rfid = objectPath.get(response, "data.rfid");
    const police = objectPath.get(response, "data.police");
    const tollgate = objectPath.get(response, "data.tollgate");
    const citylrp = objectPath.get(response, "data.citylrp");
    const traffic = objectPath.get(response, "data.traffic");
    const total = gettotal([rfid, police, tollgate, citylrp, traffic]);

    setdata({
      total: total,
      rfid: rfid,
      police: police,
      tollgate: tollgate,
      citylrp: citylrp,
      traffic: traffic,
    });
    setloading(false);
  };

  // .replace(

  //                 /(\d)(?=(\d\d\d)+(?!\d))/g,
  //                 "$1,"
  //               )
  return active == "stat" ? (
    <div className="flex mt-8 flex-col w-full shadow rounded-lg">
      <div className="flex h-20 bg-tableBackground  rounded-t-lg border-b-2 border-odt-border justify-between">
        <div className="flex">
          <div
            onClick={() => settab("total")}
            className={`flex flex-col w-32 justify-center hover:cursor-pointer items-center ${
              tab == "total"
                ? "bg-odt-active rounded-tl-lg text-odt-text"
                : "text-disabled"
            }`}
          >
            <div>Нийт</div>
          </div>
          <div
            onClick={() => settab("rfid")}
            className={`flex flex-col w-32 justify-center hover:cursor-pointer items-center ${
              tab == "rfid" ? "bg-odt-active text-odt-text" : "text-disabled"
            }`}
          >
            <div>RFID</div>
          </div>
          <div
            onClick={() => settab("police")}
            className={`flex flex-col w-32 justify-center hover:cursor-pointer items-center ${
              tab == "police" ? "bg-odt-active text-odt-text" : "text-disabled"
            }`}
          >
            <div>ТЦА</div>
          </div>
          <div
            onClick={() => settab("citylrp")}
            className={`flex flex-col w-32 justify-center hover:cursor-pointer items-center ${
              tab == "citylrp" ? "bg-odt-active text-odt-text" : "text-disabled"
            }`}
          >
            <div>CityLRP</div>
          </div>
          <div
            onClick={() => settab("tollgate")}
            className={`flex flex-col w-32 justify-center hover:cursor-pointer items-center ${
              tab == "tollgate"
                ? "bg-odt-active text-odt-text"
                : "text-disabled"
            }`}
          >
            <div>ТАЦ</div>
          </div>
          <div
            onClick={() => settab("traffic")}
            className={`flex flex-col w-32 justify-center hover:cursor-pointer items-center ${
              tab == "traffic" ? "bg-odt-active text-odt-text" : "text-disabled"
            }`}
          >
            <div>ЗХУТ</div>
          </div>
        </div>
        <div className="flex justify-center items-center px-8">
          <div className="flex justify-center items-center">
            <div className="flex mx-8 items-center">
              <ArrowLeft2
                onClick={() => {
                  if (checkDate(new Date(moment(date).subtract(1, "days"))))
                    setdate(new Date(moment(date).subtract(1, "days")));
                }}
                className="hover:cursor-pointer"
                size={20}
                color="#6236F5"
              />
              <DatePicker
                className="bg-tableBackground  text-center border-none"
                selected={date}
                dateFormat="yyyy-MM-dd"
                onChange={(date) => {
                  if (checkDate(date)) setdate(date);
                }}
              />
              <ArrowRight2
                onClick={() => {
                  if (checkDate(new Date(moment(date).add(1, "days"))))
                    setdate(new Date(moment(date).add(1, "days")));
                }}
                className="hover:cursor-pointer"
                size={20}
                color="#6236F5"
              />
            </div>
            <div className="hover:cursor-pointer" onClick={fetch}>
              <Refresh size={20} color="#6236F5" />
            </div>
          </div>
        </div>
      </div>
      {loading && (
        <div className="flex w-full h-80 justify-center items-center bg-white ">
          <Loader size={50} />
        </div>
      )}
      {tab == "total" && loading == false && (
        <div className="flex bg-white  rounded-b-lg p-4">
          <Table data={data?.total} />
        </div>
      )}
      {tab == "rfid" && loading == false && (
        <div className="flex bg-white  rounded-b-lg p-4">
          <Table data={data?.rfid} />
        </div>
      )}
      {tab == "police" && loading == false && (
        <div className="flex bg-white  rounded-b-lg p-4">
          <Table data={data?.police} />
        </div>
      )}
      {tab == "citylrp" && loading == false && (
        <div className="flex bg-white  rounded-b-lg p-4">
          <Table data={data?.citylrp} />
        </div>
      )}
      {tab == "tollgate" && loading == false && (
        <div className="flex bg-white  rounded-b-lg p-4">
          <Table data={data?.tollgate} />
        </div>
      )}
      {tab == "traffic" && loading == false && (
        <div className="flex bg-white  rounded-b-lg p-4">
          <Table data={data?.traffic} />
        </div>
      )}
    </div>
  ) : (
    <div className="flex flex-col mt-8 w-full bg-white  rounded-b-lg">
      <div className="flex h-20 w-full bg-white  rounded-t-lg border-b-2 border-odt-border justify-between">
        <div className="flex items-center ml-14">
          <div className="text-black  text-base font-medium">Жагсаалт</div>
        </div>

        <div className="flex justify-center items-center px-8">
          <div className="flex justify-center items-center">
            <div className="flex mx-8 items-center">
              <ArrowLeft2
                onClick={() => {
                  if (checkDate(new Date(moment(date).subtract(1, "days"))))
                    setdate(new Date(moment(date).subtract(1, "days")));
                }}
                className="hover:cursor-pointer"
                size={20}
                color="#6236F5"
              />
              <DatePicker
                className="bg-white  text-center border-none"
                selected={date}
                dateFormat="yyyy-MM-dd"
                onChange={(date) => {
                  if (checkDate(date)) setdate(date);
                }}
              />
              <ArrowRight2
                onClick={() => {
                  if (checkDate(new Date(moment(date).add(1, "days"))))
                    setdate(new Date(moment(date).add(1, "days")));
                }}
                className="hover:cursor-pointer"
                size={20}
                color="#6236F5"
              />
            </div>
            <div className="hover:cursor-pointer" onClick={fetch}>
              <Refresh size={20} color="#6236F5" />
            </div>
          </div>
        </div>
      </div>
      {loading ? (
        <div className="flex w-full h-80 justify-center items-center bg-white ">
          <Loader size={50} />
        </div>
      ) : (
        <div className="flex w-full px-3  pb-8">
          <StackedBar data={data} />
        </div>
      )}
    </div>
  );
};
export default Systems;
