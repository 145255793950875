import { IoMdPersonAdd } from "react-icons/io";
import { Link } from "react-router-dom";
import Table from "../list/Table";
import moment from "moment";
import { DATE_FORMAT } from "../defines";
import { AiOutlineAppstoreAdd } from "react-icons/ai";
import objectPath from "object-path";

const index = () => {
  return (
    <div className="flex flex-col ">
      <div className="flex items-center justify-between w-full h-20 py-3 px-8">
        <div className="text-xl font-semibold leading-5 text-black ">
          Дугаарын хягаарлалт
        </div>
        <Link
          to={`new`}
          className="p-2 border-2 rounded hover:cursor-pointer border-odt-primary"
        >
          <AiOutlineAppstoreAdd color="#6236F5" />
        </Link>
      </div>
      <div className="px-4">
        <Table
          url={`/limit/table`}
          sort={{ _id: -1 }}
          columns={[
            {
              label: `Тайлбар`,
              key: `description`,
              sort: true,
              filter: {
                type: "text",
              },
              render: ({ _id, description }) => {
                return (
                  <Link to={`view/${_id}`}>
                    <div className="text-odt-primary underline font-bold">
                      {description}
                    </div>
                  </Link>
                );
              },
            },
            {
              label: `Тусгай эсэх`,
              key: `isSpecial`,
              sort: true,
              filter: {
                type: "select",
                items: [
                  {
                    label: "Тийм",
                    value: true,
                  },
                  {
                    label: "Үгүй",
                    value: false,
                  },
                ],
              },
              render: ({ _id, isSpecial }) => {
                return (
                  <div
                    className={`${
                      isSpecial ? "text-green-500" : "text-black"
                    } font-bold`}
                  >
                    {isSpecial ? "Тийм" : "Үгүй"}
                  </div>
                );
              },
            },
            {
              label: `Хэрэглэгч`,
              key: `description`,
              sort: true,
              filter: {
                type: "text",
              },
              render: ({ _id, createdby }) => {
                const username = objectPath.get(createdby, "username");
                return username;
              },
            },
          ]}
        />
      </div>
    </div>
  );
};

export default index;
