import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "./index.css";
import "./providers/i18n";

import AppProvider from "./providers/app";
import Loader from "./pages/Loader";
import NotFound from "./pages/NotFound";
import Login from "./pages/Login";
import AppLayout from "./layouts/App";
import PublicLayout from "./layouts/Public";
import AuthLayout from "./layouts/Auth";
import axios from "axios";
import { API_ROOT } from "./defines";
import "react-tabs/style/react-tabs.css";
// User
import UserList from "./components/user/List";
import UserForm from "./components/user/Form";
import AsapList from "./asap/List";
import AsapExcel from "./asap/Excel";
import AsapForm from "./asap/Form";
import DrunkList from "./drunk/List";
import DrunkForm from "./drunk/Form";
import DrunkExcel from "./drunk/Excel";
import Dashboard from "./dashboard";
import Sent from "./sent";
import SentDetail from "./sent/detail";
import Vehicle from "./vehicle";
import Violation from "./violation";
import Device from "./device";
import DeviceDetail from "./device/Detail";
import DeviceExcel from "./device/Excel";
import Group from "./group";
import GroupDetail from "./group/Detail";
import Service from "./service";
import Log from "./log";
import LogDetail from "./log/detail";
import Limit from "./limit";
import LimitDetail from "./limit/Detail";

axios.defaults.baseURL = API_ROOT;
document.addEventListener("wheel", function () {
  if (document.activeElement.type === "number") {
    document.activeElement.blur();
  }
});

const root = createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <React.StrictMode>
      <Suspense fallback={<Loader />}>
        <AppProvider>
          <div className="h-full w-full flex">
            <Routes>
              <Route path="/admin" element={<Navigate to="/auth/login" />} />
              <Route path="/" element={<Navigate to="/auth/login" />} />

              <Route path="auth" element={<AuthLayout />}>
                <Route path="login" element={<Login />} />
                <Route path="logout" element={<Login from="inside" />} />
              </Route>
              <Route path="app" element={<AppLayout />}>
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="violation" element={<Violation />} />
                <Route path="vehicle" element={<Vehicle />} />

                <Route path="device" element={<Device />} />
                <Route path="device/new" element={<DeviceDetail />} />
                <Route path="device/view/:id" element={<DeviceDetail />} />
                <Route path="device/excel" element={<DeviceExcel />} />

                <Route path="group" element={<Group />} />
                <Route path="group/new" element={<GroupDetail />} />
                <Route path="group/view/:id" element={<GroupDetail />} />

                <Route path="service" element={<Service />} />
                <Route path="sent" element={<Sent />} />
                <Route path="sent/view/:id" element={<SentDetail />} />
                <Route path="log" element={<Log />} />
                <Route path="log/view/:id" element={<LogDetail />} />

                <Route path="asap" element={<AsapList />} />
                <Route path="asap/excel" element={<AsapExcel />} />
                <Route path="asap/new" element={<AsapForm />} />
                <Route path="asap/view/:id" element={<AsapForm />} />

                <Route path="drunk" element={<DrunkList />} />
                <Route path="drunk/excel" element={<DrunkExcel />} />
                <Route path="drunk/new" element={<DrunkForm />} />
                <Route path="drunk/view/:id" element={<DrunkForm />} />

                <Route path="user" element={<UserList />} />
                <Route path="user/new" element={<UserForm />} />
                <Route path="user/view/:id" element={<UserForm />} />

                <Route path="limit" element={<Limit />} />
                <Route path="limit/new" element={<LimitDetail />} />
                <Route path="limit/view/:id" element={<LimitDetail />} />
              </Route>
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
        </AppProvider>
      </Suspense>
    </React.StrictMode>
  </BrowserRouter>
);
