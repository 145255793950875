import { IoMdPersonAdd } from "react-icons/io";
import { Link } from "react-router-dom";
import Table from "../list/Table";
import moment from "moment";
import { DATE_FORMAT } from "../defines";
import objectPath from "object-path";

const index = () => {
  return (
    <div className="flex flex-col ">
      <div className="flex items-center justify-between w-full h-20 py-3 px-8">
        <div className="text-xl font-semibold leading-5 text-black ">
          Илгээсэн
        </div>
      </div>
      <div className="px-4">
        <Table
          url={`/send/table`}
          excelUrl={`/send/excel`}
          sort={{ _id: -1 }}
          columns={[
            {
              label: `Улсын №`,
              key: `plate`,
              sort: true,
              filter: {
                type: "text",
              },
              render: ({ _id, violation }) => {
                return (
                  <Link to={`view/${_id}`}>
                    <div className="text-odt-primary underline font-bold">
                      {objectPath.get(violation, "plate")}
                    </div>
                  </Link>
                );
              },
            },
            {
              label: `Огноо`,
              sort: true,
              key: "created",
              filter: {
                type: "date",
              },
              render: ({ _id, created }) => {
                return (
                  <div className="">{moment(created).format(DATE_FORMAT)}</div>
                );
              },
            },
            {
              label: `Хэрэглэгч`,
              key: `mark`,
              sort: true,
              filter: {
                type: "text",
              },
              render: ({ _id, user }) => {
                const username = objectPath.get(user, "username");
                return <div className="">{username}</div>;
              },
            },
            {
              label: `APP хувилбар`,
              key: `version`,
              sort: true,
              filter: {
                type: "text",
              },
            },
            {
              label: `Төхөөрөмж`,
              key: `mark`,
              sort: true,
              filter: {
                type: "text",
              },
              render: ({ _id, device }) => {
                const name = objectPath.get(device, "name");
                return <div className="">{name}</div>;
              },
            },
            {
              label: `Чиглэл`,
              key: `mark`,
              sort: true,
              filter: {
                type: "text",
              },
              render: ({ _id, device }) => {
                const direction = objectPath.get(device, "direction");
                return <div className="">{direction}</div>;
              },
            },

            {
              label: `Татвар`,
              sort: true,
              filter: {
                type: "text",
              },
              render: ({ _id, violation }) => {
                const tax = objectPath.get(violation, "tax");
                return (
                  <div
                    className={`${
                      tax ? "text-red-500" : "text-green-500"
                    } font-medium`}
                  >
                    {tax ? "Зөрчилтэй" : "Зөрчилгүй"}
                  </div>
                );
              },
            },
            {
              label: `Даатгал`,
              sort: true,
              filter: {
                type: "text",
              },
              render: ({ _id, violation }) => {
                const insurance = objectPath.get(violation, "insurance");
                return (
                  <div
                    className={`${
                      insurance ? "text-red-500" : "text-green-500"
                    } font-medium`}
                  >
                    {insurance ? "Зөрчилтэй" : "Зөрчилгүй"}
                  </div>
                );
              },
            },
            {
              label: `Оншилгоо`,
              sort: true,
              filter: {
                type: "text",
              },
              render: ({ _id, violation }) => {
                const inspection = objectPath.get(violation, "inspection");
                return (
                  <div
                    className={`${
                      inspection ? "text-red-500" : "text-green-500"
                    } font-medium`}
                  >
                    {inspection ? "Зөрчилтэй" : "Зөрчилгүй"}
                  </div>
                );
              },
            },
            {
              label: `Торгууль`,
              sort: true,
              filter: {
                type: "text",
              },
              render: ({ _id, violation }) => {
                const penalty = objectPath.get(violation, "penalty");
                return (
                  <div
                    className={`${
                      penalty ? "text-red-500" : "text-green-500"
                    } font-medium`}
                  >
                    {penalty ? "Зөрчилтэй" : "Зөрчилгүй"}
                  </div>
                );
              },
            },
            {
              label: `Жолоодох эрхгүй`,
              sort: true,
              filter: {
                type: "text",
              },
              render: ({ _id, violation }) => {
                const drunk = objectPath.get(violation, "drunk");
                return (
                  <div
                    className={`${
                      drunk ? "text-red-500" : "text-green-500"
                    } font-medium`}
                  >
                    {drunk ? "Зөрчилтэй" : "Зөрчилгүй"}
                  </div>
                );
              },
            },
            {
              label: `Эрэн сурвалжлалт`,
              sort: true,
              filter: {
                type: "text",
              },
              render: ({ _id, violation }) => {
                const asap = objectPath.get(violation, "asap");
                return (
                  <div
                    className={`${
                      asap ? "text-red-500" : "text-green-500"
                    } font-medium`}
                  >
                    {asap ? "Зөрчилтэй" : "Зөрчилгүй"}
                  </div>
                );
              },
            },
          ]}
        />
      </div>
    </div>
  );
};

export default index;
