import * as Yup from "yup";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { SHA256 } from "crypto-js";
import { arrayValidation, stringValidation } from "../providers/validations";
import Field from "../components/Field";
import { DatePicker, Select, Text, Toggle } from "../inputs";
import { FieldArray, Form } from "../template";
import {
  MapContainer,
  TileLayer,
  useMap,
  Popup,
  Marker,
  useMapEvents,
} from "react-leaflet";
import objectPath from "object-path";

const Detail = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [position, setPosition] = useState(null);

  const props = {
    editable: true,
    isDeletable: true,
    model: "limit",
    text: id ? "Хязгаарлалтын мэдээлэл" : "Хязгаарлалт бүртгэх",
    id: id,
    init: {},
    setPosition: setPosition,

    validationSchema: Yup.object().shape({
      description: stringValidation(true),
      list: arrayValidation(true),
    }),
  };
  const beforeSubmit = (data) => {
    console.log("🚀 ~ beforeSubmit ~ data:", data);
    return data;
  };
  return (
    <Form {...props} beforeSubmit={beforeSubmit}>
      {({ submited, disabled, values }) => {
        return (
          <div className="p-5 bg-white  grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="flex flex-col gap-4">
              <Field
                name="description"
                label={`Тайлбар`}
                disabled={disabled}
                component={Text}
                type="text"
                className={`text-black `}
              />
              <Field
                name="isSpecial"
                label={`Тусгай эсэх`}
                disabled={disabled}
                component={Toggle}
                type="text"
                className="align-middle justify-between text-black "
              />
              {values.isSpecial && (
                <FieldArray
                  name="prohibit"
                  label="Тусгай хязгаарлалт"
                  disabled={disabled}
                  initial={{
                    day: "",
                    start: "",
                    end: "",
                    list: [],
                  }}
                  duplicatable={true}
                  renderItem={(question, qIndex) => (
                    <div className="flex flex-row">
                      <Field
                        name={`prohibit.${qIndex}.day`}
                        label="Огноо"
                        disabled={disabled}
                        component={DatePicker}
                        format="yyyy-MM-DD"
                        className={"w-44 min-w-48 mx-2"}
                      />
                      <Field
                        name={`prohibit.${qIndex}.start`}
                        label="Эхлэх цаг"
                        disabled={disabled}
                        component={Text}
                        className={"w-24 max-w-24 mx-2"}
                        type="number"
                      />
                      <Field
                        name={`prohibit.${qIndex}.end`}
                        label="Дуусах цаг"
                        disabled={disabled}
                        component={Text}
                        className={"w-24 max-w-24 mx-2"}
                        type="number"
                      />
                      <Field
                        name={`prohibit.${qIndex}.list`}
                        label="Улсын дугаар"
                        disabled={disabled}
                        component={Text}
                        className={"w-32 max-w-32 mx-2"}
                        type="text"
                        placeholder={"1,2,3,4,5"}
                      />
                    </div>
                  )}
                />
              )}
            </div>
            <div className="flex flex-col gap-4">
              <Field
                name="list"
                label={`Төхөөрөмж`}
                disabled={disabled}
                isMulti={true}
                component={Select}
                axio={"/device/list"}
                axioAdapter={(data) => {
                  return data.map(({ _id, name, direction }) => ({
                    value: _id,
                    label: name,
                  }));
                }}
              />
            </div>
          </div>
        );
      }}
    </Form>
  );
};

export default Detail;
