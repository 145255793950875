import React from "react";
import Table from "../list/Table";
import { Link } from "react-router-dom";
import { IoMdAdd, IoMdPersonAdd } from "react-icons/io";
import { useTranslation } from "react-i18next";
import objectPath from "object-path";
import { LiaFileUploadSolid } from "react-icons/lia";

const Permission = [
  {
    label: `Админ`,
    value: `admin`,
  },
  {
    label: `Цагдаа`,
    value: `police`,
  },

  {
    label: `Цагдаа админ`,
    value: `police_admin`,
  },
];
const List = () => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col ">
      <div className="flex items-center justify-between w-full h-20 py-3 px-8">
        <div className="text-xl font-semibold leading-5 text-black ">
          Эрэн сурвалжлалт
        </div>

        <Link
          to={`new`}
          className="p-2 border-2 rounded hover:cursor-pointer border-odt-primary"
        >
          <IoMdAdd color="#6236F5" />
        </Link>
      </div>
      <div className="px-4">
        <Table
          url={`/asap/table`}
          stat={`/asap/bylocation`}
          excelUrl={`/asap/excel`}
          upload={true}
          sort={{ _id: -1 }}
          columns={[
            {
              label: `Улсын №`,
              key: `plate`,
              sort: true,
              filter: {
                type: "text",
              },
              render: ({ _id, plate }) => {
                return (
                  <Link to={`view/${_id}`}>
                    <div className="text-odt-primary font-semibold underline">
                      {plate}
                    </div>
                  </Link>
                );
              },
            },
            {
              label: `Харьяа`,
              key: `location`,
              sort: true,
              filter: {
                type: "select",
                items: [
                  {
                    label: `Нийслэл`,
                    value: `UB`,
                  },
                  {
                    label: `Орон нутаг`,
                    value: `COUNTRY`,
                  },
                ],
              },
              render: ({ location }) => {
                return (
                  <div>
                    {location == "UB"
                      ? "Нийслэл"
                      : location == "COUNTRY"
                      ? "Орон нутаг"
                      : ""}
                  </div>
                );
              },
            },
            {
              label: `Төрөл`,
              key: `type`,
              sort: true,
              filter: {
                type: "select",
                items: [
                  {
                    label: `Яаралтай`,
                    value: `Яаралтай`,
                  },
                  {
                    label: `Хэвийн`,
                    value: `Хэвийн`,
                  },
                ],
              },
            },
            {
              label: `Тайлбар`,
              key: `description`,
              sort: true,
              filter: {
                type: "text",
              },
            },
            {
              label: `Хэрэглэгч`,
              key: `description`,
              sort: true,
              filter: {
                type: "text",
              },
              render: ({ _id, createdby }) => {
                const username = objectPath.get(createdby, "username");
                return username;
              },
            },
          ]}
        />
      </div>
    </div>
  );
};

export default List;
