import { useState, useEffect } from "react";
import Systems from "./systems";
import Violation from "./violation";
import { Moon, Sun1 } from "iconsax-react";

const Index = () => {
  const [active, setactive] = useState("stat");
  const [isDark, setisDark] = useState(false);

  return (
    <div className="flex flex-col">
      <div className="flex items-center justify-between bg-white  w-full h-20 shadow px-4 py-3">
        <div className="text-xl font-semibold leading-5 text-black ">
          Хянах самбар
        </div>
        <div className="flex items-center">
          <div className="flex rounded-xl p-2 items-center">
            <div
              onClick={() => setactive("stat")}
              className={`p-2 mx-1 hover:cursor-pointer  rounded-xl ${
                active == "stat" ? "text-white bg-odt-primary" : "text-disabled"
              }`}
            >
              Жагсаалтаар
            </div>
            <div
              onClick={() => setactive("graph")}
              className={`p-2 mx-1 hover:cursor-pointer  rounded-xl ${
                active == "graph"
                  ? "text-white bg-odt-primary"
                  : "text-disabled"
              }`}
            >
              Графикаар
            </div>
          </div>
          <div className="pl-2 border-l border-l-odt-border dark:border-l-odt-disabled">
            <div
              onClick={() => {
                if (isDark) {
                  document.body.classList.remove("dark");
                  document.body.classList.add("light");
                  setisDark(false);
                } else {
                  document.body.classList.remove("light");
                  document.body.classList.add("dark");
                  setisDark(true);
                }

                // document.body.classList.toggle("dark");
              }}
              className={`p-2 mx-1 hover:cursor-pointer  rounded-xl ${"text-disabled"}`}
            >
              {!isDark ? <Moon /> : <Sun1 />}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col p-4 z-10">
        <Violation active={active} />
        <Systems active={active} />
      </div>
    </div>
  );
};

export default Index;
