import { useParams } from "react-router-dom";
import { mainApi } from "../providers/api";
import { useState, useEffect } from "react";
import Loader from "../components/Loader";
import Text from "../components/comp/Text";
import objectPath from "object-path";
import { Back } from "../template";
import { DATE_FORMAT } from "../defines";
import moment from "moment";
const Detail = () => {
  const { id } = useParams();
  const [loading, setloading] = useState(false);
  const [data, setdata] = useState();
  useEffect(() => {
    fetch();
  }, []);
  const fetch = async () => {
    setloading(true);
    const response = await mainApi({
      url: `/send/view/${id}`,
      method: `GET`,
    });

    setdata(response?.data);
    setloading(false);
  };
  return (
    <div className="flex flex-col">
      <div className="flex items-center mb-4 p-2">
        <div>{<Back />}</div>
        <div className="text-base ml-2 font-semibold">{`Дэлгэрэнгүй`}</div>
      </div>
      {loading ? (
        <div className="flex h-screen w-full justify-center items-center">
          <Loader size={50} />
        </div>
      ) : (
        <div className="p-4">
          <div className="flex flex-wrap p-4 bg-white  rounded shadow w-full">
            <div className="flex flex-col w-full md:w-1/2 p-2">
              <Text
                label={"Улсын №"}
                value={objectPath.get(data, "violation.plate")}
              />
              <Text
                label={"Марк"}
                value={objectPath.get(data, "violation.mark")}
              />
              <Text
                label={"Модел"}
                value={objectPath.get(data, "violation.model")}
              />
              <Text
                label={"Татвар"}
                className={
                  objectPath.get(data, "violation.drunk")
                    ? "text-red-500 font-medium"
                    : "text-green-500 font-medium"
                }
                value={
                  objectPath.get(data, "violation.tax")
                    ? "Зөрчилтэй"
                    : "Зөрчилгүй"
                }
              />
              <Text
                label={"Даатгал"}
                className={
                  objectPath.get(data, "violation.insurance")
                    ? "text-red-500 font-medium"
                    : "text-green-500 font-medium"
                }
                value={
                  objectPath.get(data, "violation.insurance")
                    ? "Зөрчилтэй"
                    : "Зөрчилгүй"
                }
              />
              <Text
                label={"Оншилгоо"}
                className={
                  objectPath.get(data, "violation.inspection")
                    ? "text-red-500 font-medium"
                    : "text-green-500 font-medium"
                }
                value={
                  objectPath.get(data, "violation.inspection")
                    ? "Зөрчилтэй"
                    : "Зөрчилгүй"
                }
              />
              <Text
                label={"Торгууль"}
                className={
                  objectPath.get(data, "violation.penalty")
                    ? "text-red-500 font-medium"
                    : "text-green-500 font-medium"
                }
                value={
                  objectPath.get(data, "violation.penalty")
                    ? "Зөрчилтэй"
                    : "Зөрчилгүй"
                }
              />
              <Text
                label={"Эрэн сурвалжлалт"}
                className={
                  objectPath.get(data, "violation.asap")
                    ? "text-red-500 font-medium"
                    : "text-green-500 font-medium"
                }
                value={
                  objectPath.get(data, "violation.asap")
                    ? "Зөрчилтэй"
                    : "Зөрчилгүй"
                }
              />
              <Text
                label={"Жолоодох эрхгүй"}
                className={
                  objectPath.get(data, "violation.drunk")
                    ? "text-red-500 font-medium"
                    : "text-green-500 font-medium"
                }
                value={
                  objectPath.get(data, "violation.drunk")
                    ? "Зөрчилтэй"
                    : "Зөрчилгүй"
                }
              />
            </div>
            <div className="flex flex-col w-full md:w-1/2 p-2">
              <Text
                label={"Төхөөрөмж"}
                value={objectPath.get(data, "device.name")}
              />
              <Text
                label={"Чиглэл"}
                value={objectPath.get(data, "device.direction")}
              />
              <Text
                label={"Антине"}
                value={objectPath.get(data, "device.antine")}
              />
              <Text
                label={"Хэрэглэгч"}
                value={objectPath.get(data, "user.username")}
              />
              <Text
                label={"Нэр"}
                value={`${objectPath.get(
                  data,
                  "user.lastname"
                )} ${objectPath.get(data, "user.firstname")}`}
              />
              <Text label={"Цол"} value={objectPath.get(data, "user.rank")} />
              <Text
                label={"Газар,хэлтэс"}
                value={objectPath.get(data, "user.department")}
              />
              <Text
                label={"Албан тушаал"}
                value={objectPath.get(data, "user.position")}
              />
              <Text
                label={"Огноо"}
                className="font-medium"
                value={moment(objectPath.get(data, "created")).format(
                  DATE_FORMAT
                )}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default Detail;
