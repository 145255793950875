import * as Yup from "yup";
import { stringValidation } from "../providers/validations";
import { Formik, Form } from "formik";
import Field from "../components/FieldCustom";
import Loader from "../template/Loader";
import LoaderCustom from "../components/Loader";
import { useState } from "react";
import objectPath from "object-path";
import axios from "axios";
import { mainApi } from "../providers/api";

import { DATE_FORMAT } from "../defines";
import moment from "moment";
const Index = () => {
  const [loading, setloading] = useState(false);
  const [loadingTax, setloadingTax] = useState(false);
  const [tax, settax] = useState();
  const [loadingInsur, setloadingInsur] = useState(false);
  const [insurance, setinsurance] = useState();
  const [loadingInspec, setloadingInspec] = useState(false);
  const [inspection, setinspection] = useState();
  const [loadingPenalty, setloadingPenalty] = useState(false);
  const [penalty, setpenalty] = useState();
  const [loadingDrunk, setloadingDrunk] = useState(false);
  const [drunk, setdrunk] = useState(null);
  const [loadingAsap, setloadingAsap] = useState(false);
  const [asap, setasap] = useState();

  const onSubmit = async ({ plateNumber, vinNumber }) => {
    // setloading(true);
    checkTax(plateNumber);
    checkInsurance(plateNumber);
    checkInspection(vinNumber);
    checkPenalty(plateNumber);
    checkDrunk(plateNumber);
    checkAsap(plateNumber);
    // setloading(false);
  };
  const checkTax = async (plateNumber) => {
    setloadingTax(true);
    const response = await mainApi({
      url: `/service/tax`,
      method: "POST",
      auth: {
        username: "odt",
        password: "odt123456",
      },
      data: {
        plateNumber: plateNumber,
        time_out: 600000,
      },
    });

    const list = objectPath.get(
      response,
      "data.result.response.return.response"
    );
    const temp = list?.listData.filter(
      (t) => t.year == new Date().getFullYear()
    );

    settax({
      code: objectPath.get(response, "data.result.response.return.resultCode"),
      message: objectPath.get(
        response,
        "data.result.response.return.resultMessage"
      ),
      result: temp?.length == 0 ? null : objectPath.get(temp, "0"),
    });
    setloadingTax(false);
  };

  const checkInsurance = async (plateNumber) => {
    setloadingInsur(true);
    const response = await mainApi({
      url: `/service/insurance`,
      method: "POST",
      auth: {
        username: "odt",
        password: "odt123456",
      },
      data: {
        plateNumber: plateNumber,
        time_out: 600000,
      },
    });

    setinsurance(objectPath.get(response, "data.result.response.Contracts.0"));
    setloadingInsur(false);
  };
  const checkInspection = async (vinNumber) => {
    setloadingInspec(true);
    const response = await axios({
      url: `/service/inspection`,
      method: "POST",
      auth: {
        username: "odt",
        password: "odt123456",
      },
      data: {
        cabinNumber: vinNumber,
      },
    });

    setinspection({
      active: objectPath.get(response, "data.result.isPassed"),
      code: objectPath.get(response, "data.result.response.resultCode"),
      message: objectPath.get(response, "data.result.response.resultMessage"),
      result: objectPath.get(response, "data.result.response.response"),
    });
    setloadingInspec(false);
  };
  const checkPenalty = async (plateNumber) => {
    setloadingPenalty(true);
    const response = await axios({
      url: `/service/penalty`,
      method: "POST",
      auth: {
        username: "odt",
        password: "odt123456",
      },
      data: {
        plateNumber: plateNumber,
      },
    });

    const temp = objectPath.get(
      response,
      "data.result.response.return.response"
    );

    let total = 0,
      totalAmount = 0,
      notPaid = 0,
      notPaidAmount = 0;
    temp?.listData?.map((row) => {
      total++;
      totalAmount += objectPath.get(row, "amount");
      if (objectPath.get(row, "paid") == false) {
        notPaid++;
        notPaidAmount += objectPath.get(row, "amount");
      }
    });

    setpenalty({
      code: objectPath.get(response, "data.result.response.return.resultCode"),
      message: objectPath.get(
        response,
        "data.result.response.return.resultMessage"
      ),
      total: total,
      totalAmount: totalAmount,
      notPaid: notPaid,
      notPaidAmount: notPaidAmount,
    });

    setloadingPenalty(false);
  };

  const checkAsap = async (plateNumber) => {
    setloadingAsap(true);

    const response = await axios({
      url: `/asap/check/${plateNumber}`,
      method: "GET",
    });

    setasap(response.data);
    setloadingAsap(false);
  };
  const checkDrunk = async () => {
    setloadingDrunk(true);
    // const response = await axios({

    //   url: `/tax`,
    //   method: "POST",
    //   data: {
    //     plateNumber: "123456789",
    //     vinNumber: "123456789",
    //   },
    // });
    setdrunk(undefined);
    setloadingDrunk(false);
  };

  const Validation = Yup.object().shape({
    plateNumber: Yup.string().required(`Улсын дугаар оруулна уу.`),
    vinNumber: Yup.string().required(`Арлын дугаар оруулна уу.`),
  });
  return (
    <div className="flex flex-col ">
      <div className="p-2">
        <Formik
          initialValues={{
            plateNumber: "",
            vinNumber: "",
          }}
          validationSchema={Validation}
          onSubmit={onSubmit}
        >
          {({}) => (
            <Form className="flex bg-white  p-4 shadow rounded items-start">
              <Field
                autoFocus={true}
                name="plateNumber"
                placeholder={`Улсын дугаар`}
                className="uppercase text-sm w-48 h-12"
              />
              <Field
                name="vinNumber"
                autoFocus={true}
                placeholder={`Арлын дугаар`}
                className="uppercase text-sm w-48 ml-4 h-12"
              />

              <button
                className="w-48 max-w-48 ml-8 h-12 bg-odt-primary"
                type="submit"
              >
                {loading ? <Loader /> : `Шалгах`}
              </button>
            </Form>
          )}
        </Formik>
      </div>
      <div className="flex flex-wrap mt-2">
        <div className="flex flex-col w-1/2 p-2">
          <div className="flex flex-col bg-white  rounded shadow p-3">
            <div className="font-semibold border-b border-b-odt-primary pb-2 mb-2 text-black ">
              Татвар
            </div>
            {loadingTax ? (
              <div className="flex justify-center items-center min-h-56">
                <LoaderCustom size={50} />
              </div>
            ) : (
              <div className="flex flex-col max-h-56 p-2">
                {objectPath.get(tax, "code") == 0 ? (
                  <div className="flex flex-col">
                    <div className="flex flex-wrap">
                      <div className="w-full md:w-1/2 flex flex-col">
                        <div className="italic ">Татварын жил</div>
                        <div className="font-medium ">
                          {objectPath.get(tax, "result.year")}
                        </div>
                      </div>
                      <div className="w-full md:w-1/2 flex flex-col">
                        <div className="italic ">Төлсөн огноо</div>
                        <div className="font-medium ">
                          {objectPath.get(tax, "result.paidDate")}
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-wrap mt-2">
                      <div className="w-full md:w-1/2 flex flex-col">
                        <div className="italic ">АБТөлбөр</div>
                        <div className="font-medium ">
                          {`${objectPath.get(tax, "result.airPollAmount")}₮`}
                        </div>
                      </div>
                      <div className="w-full md:w-1/2 flex flex-col">
                        <div className="italic ">Төлсөн</div>
                        <div className="font-medium ">
                          {`${objectPath.get(
                            tax,
                            "result.paidAirPollAmount"
                          )}₮`}
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-wrap mt-2">
                      <div className="w-full md:w-1/2 flex flex-col">
                        <div className="italic ">АЗАТөлбөр</div>
                        <div className="font-medium ">
                          {`${objectPath.get(tax, "result.trafficAmount")}₮`}
                        </div>
                      </div>
                      <div className="w-full md:w-1/2 flex flex-col">
                        <div className="italic ">Төлсөн</div>
                        <div className="font-medium ">
                          {`${objectPath.get(
                            tax,
                            "result.paidTrafficAmount"
                          )}₮`}
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-wrap mt-2">
                      <div className="w-full md:w-1/2 flex flex-col">
                        <div className="italic ">АТБӨЯХТатвар</div>
                        <div className="font-medium ">
                          {`${objectPath.get(tax, "result.taxAmount")}₮`}
                        </div>
                      </div>
                      <div className="w-full md:w-1/2 flex flex-col">
                        <div className="italic ">Төлсөн</div>
                        <div className="font-medium ">
                          {`${objectPath.get(tax, "result.paidTaxAmount")}₮`}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="flex justify-center items-center h-full min-h-56">
                    <div className="text-gray-500 font-medium ">
                      {objectPath.get(tax, "message")
                        ? objectPath.get(tax, "message")
                        : "Мэдээлэл олдсонгүй"}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col w-1/2 p-2">
          <div className="flex flex-col bg-white  rounded shadow p-3">
            <div className="font-semibold border-b border-b-odt-primary pb-2 mb-2 text-black ">
              Даатгал
            </div>
            {loadingInsur ? (
              <div className="flex justify-center items-center min-h-56">
                <LoaderCustom size={50} />
              </div>
            ) : insurance == undefined ? (
              <div className="flex flex-col min-h-56 justify-center items-center">
                <div className="text-gray-500 font-medium ">
                  Мэдээлэл олдсонгүй
                </div>
              </div>
            ) : (
              <div className="flex flex-col min-h-56 p-2">
                <div className="flex flex-wrap">
                  <div className="w-full md:w-1/2 flex flex-col">
                    <div className="italic ">Компани</div>
                    <div className="font-medium ">
                      {objectPath.get(insurance, "insurance_company")}
                    </div>
                  </div>
                  <div className="w-full md:w-1/2 flex flex-col">
                    <div className="italic ">Идэвхтэй эсэх</div>
                    <div
                      className={`  font-medium ${
                        objectPath.get(insurance, "insurer_state") == "Active"
                          ? "text-green-500"
                          : "text-red-500"
                      }`}
                    >
                      {objectPath.get(insurance, "insurer_state") == "Active"
                        ? "Тийм"
                        : "Үгүй"}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col w-1/2 p-2">
          <div className="flex flex-col bg-white  rounded shadow p-3">
            <div className="font-semibold border-b border-b-odt-primary pb-2 mb-2 text-black ">
              Оншилгоо
            </div>
            {loadingInspec ? (
              <div className="flex justify-center items-center min-h-32">
                <LoaderCustom size={50} />
              </div>
            ) : (
              <div className="flex flex-col min-h-32 p-2">
                {objectPath.get(inspection, "code") == 0 ? (
                  <div className="flex flex-col">
                    <div className="flex flex-wrap">
                      <div className="w-full md:w-1/2 flex flex-col">
                        <div className="italic ">Орсон огноо</div>
                        <div className="font-medium ">
                          {moment(
                            objectPath.get(inspection, "result.checkDate")
                          ).format(DATE_FORMAT)}
                        </div>
                      </div>
                      <div className="w-full md:w-1/2 flex flex-col">
                        <div className="italic ">Дуусах огноо</div>
                        <div className="font-medium ">
                          {moment(
                            objectPath.get(inspection, "result.expireDate")
                          ).format(DATE_FORMAT)}
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-wrap mt-2">
                      <div className="w-full md:w-1/2 flex flex-col">
                        <div className="italic ">Тэнцсэн эсэх</div>
                        <div
                          className={`font-medium   ${
                            objectPath.get(inspection, "result.passed") == true
                              ? "text-green-500"
                              : "text-red-500"
                          }`}
                        >
                          {`${
                            objectPath.get(inspection, "result.passed")
                              ? "Тийм"
                              : "Үгүй"
                          }`}
                        </div>
                      </div>
                      <div className="w-full md:w-1/2 flex flex-col">
                        <div className="italic ">Хүчинтэй эсэх</div>
                        <div
                          className={`font-medium  ${
                            objectPath.get(inspection, "active") == true
                              ? "text-green-500"
                              : "text-red-500"
                          }`}
                        >
                          {`${
                            objectPath.get(inspection, "active")
                              ? "Тийм"
                              : "Үгүй"
                          }`}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="flex justify-center items-center h-full">
                    <div className="text-gray-500 font-medium ">
                      {objectPath.get(inspection, "message")
                        ? objectPath.get(penalty, "message")
                        : "Мэдээлэл олдсонгүй"}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col w-1/2 p-2">
          <div className="flex flex-col bg-white  rounded shadow p-3">
            <div className="font-semibold border-b border-b-odt-primary pb-2 mb-2 text-black ">
              Торгууль
            </div>
            {loadingPenalty ? (
              <div className="flex justify-center items-center min-h-32">
                <LoaderCustom size={50} />
              </div>
            ) : (
              <div className="flex flex-col min-h-32 p-2">
                {objectPath.get(penalty, "code") == 0 ? (
                  <div className="flex flex-col">
                    <div className="flex flex-wrap">
                      <div className="w-full md:w-1/2 flex flex-col">
                        <div className="italic ">Нийт</div>
                        <div className="font-medium ">
                          {objectPath.get(penalty, "total")}
                        </div>
                      </div>
                      <div className="w-full md:w-1/2 flex flex-col">
                        <div className="italic ">Нийт дүн</div>
                        <div className="font-medium ">
                          {`${objectPath.get(penalty, "totalAmount")}₮`}
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-wrap mt-2">
                      <div className="w-full md:w-1/2 flex flex-col">
                        <div className="italic ">Төлөөгүй</div>
                        <div
                          className={`font-medium  ${
                            objectPath.get(penalty, "notPaid") > 0
                              ? "text-red-500"
                              : "text-green-500"
                          }`}
                        >
                          {`${objectPath.get(penalty, "notPaid")}`}
                        </div>
                      </div>
                      <div className="w-full md:w-1/2 flex flex-col">
                        <div className="italic ">Төлөөгүй дүн</div>
                        <div
                          className={`font-medium   ${
                            objectPath.get(penalty, "notPaidAmount") > 0
                              ? "text-red-500"
                              : "text-green-500"
                          }`}
                        >
                          {`${objectPath.get(penalty, "notPaidAmount")}₮`}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="flex justify-center items-center h-full">
                    <div className="text-gray-500 font-medium ">
                      {objectPath.get(penalty, "message")
                        ? objectPath.get(penalty, "message")
                        : "Мэдээлэл олдсонгүй"}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        <div className="flex flex-col w-1/2 p-2">
          <div className="flex flex-col bg-white  rounded shadow p-3">
            <div className="font-semibold border-b border-b-odt-primary pb-2 mb-2 text-black ">
              Эрэн сурвалжлалт
            </div>
            {loadingInsur ? (
              <div className="flex justify-center items-center min-h-56">
                <LoaderCustom size={50} />
              </div>
            ) : asap == undefined || asap == null ? (
              <div className="flex flex-col min-h-56 justify-center items-center">
                <div className="text-gray-500 font-medium ">
                  Мэдээлэл олдсонгүй
                </div>
              </div>
            ) : (
              <div className="flex flex-col max-h-56 p-2">
                <div className="flex flex-wrap">
                  <div className="w-full min-h-56 flex flex-col">
                    <div className="italic  ">
                      Эрэн сурвалжлагдаж буй шалтгаан
                    </div>
                    <div className="font-medium text-red-500">
                      {objectPath.get(asap, "description")}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col w-1/2 p-2">
          <div className="flex flex-col bg-white  rounded shadow p-3">
            <div className="font-semibold border-b border-b-odt-primary pb-2 mb-2 text-black ">
              Жолоодох эрхгүй
            </div>
            {loadingDrunk ? (
              <div className="flex justify-center items-center min-h-56">
                <LoaderCustom size={50} />
              </div>
            ) : drunk == undefined ? (
              <div className="flex flex-col min-h-56 justify-center items-center">
                <div className="text-gray-500 font-medium ">
                  Мэдээлэл олдсонгүй
                </div>
              </div>
            ) : (
              <div className="flex flex-col min-h-56 p-2"></div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
