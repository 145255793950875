import {
  RadialBarChart,
  RadialBar,
  Legend,
  ResponsiveContainer,
} from "recharts";
const getPercentage = (total, value) => {
  return ((value / total) * 100).toFixed(2);
};
const Radial = ({
  first,
  first_data,
  first_color,
  second,
  second_data,
  second_color,
}) => {
  const fname =
    first +
    " - " +
    first_data?.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
  const sname =
    second +
    " - " +
    second_data?.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
  const data = [
    {
      name: fname,
      val: getPercentage(first_data + second_data, first_data),
      fill: first_color,
    },
    {
      name: sname,
      val: getPercentage(first_data + second_data, second_data),
      fill: second_color,
    },
  ];

  return (
    <ResponsiveContainer width="100%" height="100%">
      <RadialBarChart
        cx="50%"
        cy="50%"
        innerRadius="40%"
        outerRadius="90%"
        barSize={15}
        data={data}
      >
        <RadialBar
          minAngle={15}
          label={{ position: "insideStart", fill: "#000" }}
          background
          clockWise
          dataKey="val"
          isAnimationActive
          maxPointSize={100}
          minPointSize={0}
        />
        <Legend
          iconSize={6}
          layout="vertical"
          verticalAlign="middle"
          wrapperStyle={{
            top: "100%",
            bottom: 0,
            transform: "translate(0, -50%)",
            lineHeight: "24px",
            fontSize: 14,
            fontWeight: "500",
          }}
        />
      </RadialBarChart>
    </ResponsiveContainer>
  );
};
export default Radial;
