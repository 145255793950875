import * as Yup from "yup";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { SHA256 } from "crypto-js";
import { arrayValidation, stringValidation } from "../providers/validations";
import Field from "../components/Field";
import { Select, Text } from "../inputs";
import { Form } from "../template";
import {
  MapContainer,
  TileLayer,
  useMap,
  Popup,
  Marker,
  useMapEvents,
} from "react-leaflet";
import objectPath from "object-path";

const UserForm = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [position, setPosition] = useState(null);
  const LocationMarker = () => {
    const map = useMapEvents({
      click(e) {
        setPosition(e?.latlng);
        map.flyTo(e?.latlng, map.getZoom());
      },
    });

    return position == null ? null : (
      <Marker position={position}>
        <Popup>Цэгийн байршил</Popup>
      </Marker>
    );
  };
  const props = {
    editable: true,
    isDeletable: true,
    model: "group",
    text: id ? "Групийн мэдээлэл" : "Груп бүртгэх",
    id: id,
    init: {},
    setPosition: setPosition,
    positionType: "point",
    validationSchema: Yup.object().shape({
      name: stringValidation(true),
      list: arrayValidation(true),
    }),
  };
  const beforeSubmit = (data) => {
    return {
      ...data,
      coordinates: {
        lat: position?.lat,
        lng: position?.lng,
      },
    };
  };
  return (
    <Form {...props} beforeSubmit={beforeSubmit}>
      {({ submited, disabled, values }) => {
        return (
          <div className="p-5 bg-white  grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="flex flex-col gap-4">
              <Field
                name="name"
                label={`Нэр`}
                disabled={disabled}
                component={Text}
                type="text"
                className={`text-black `}
              />
              <Field
                name="list"
                label={`Эгнээ`}
                disabled={disabled}
                component={Select}
                isMulti
                axio={`/device/list`}
                axioAdapter={(data) => {
                  return data.map(({ _id, name, direction }) => ({
                    value: _id,
                    label: name + " " + direction,
                  }));
                }}
                type="text"
              />

              <div>
                <label className="label order-1">{"Байршил"}</label>
                <Text
                  className="text-blue-500"
                  value={
                    position
                      ? position?.lat + " , " + position?.lng
                      : "Газрын зураг дээр цэглэх"
                  }
                />
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <MapContainer
                center={[47.919108, 106.91752]}
                zoom={13}
                style={{ width: 500, height: 500 }}
                scrollWheelZoom={true}
              >
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <LocationMarker />
              </MapContainer>
            </div>
          </div>
        );
      }}
    </Form>
  );
};

export default UserForm;
