import { IoMdPersonAdd } from "react-icons/io";
import { Link } from "react-router-dom";
import Table from "../list/Table";
import moment from "moment";
import { DATE_FORMAT } from "../defines";
import { TbDeviceDesktopPlus } from "react-icons/tb";
import objectPath from "object-path";

const index = () => {
  return (
    <div className="flex flex-col ">
      <div className="flex items-center justify-between w-full h-20 py-3 px-8">
        <div className="text-xl font-semibold leading-5 text-black ">
          Төхөөрөмж
        </div>
        <Link
          to={`new`}
          className="p-2 border-2 rounded hover:cursor-pointer border-odt-primary"
        >
          <TbDeviceDesktopPlus color="#6236F5" />
        </Link>
      </div>
      <div className="px-4">
        <Table
          url={`/device/table`}
          excelUrl={`/device/excel`}
          sort={{ _id: -1 }}
          columns={[
            {
              label: `Нэр`,
              key: `name`,
              sort: true,
              filter: {
                type: "text",
              },
              render: ({ _id, name }) => {
                return (
                  <Link to={`view/${_id}`}>
                    <div className="text-odt-primary underline font-bold">
                      {name}
                    </div>
                  </Link>
                );
              },
            },

            {
              label: `Хост`,
              key: `host_name`,
              sort: true,
              filter: {
                type: "text",
              },
            },
            {
              label: `Антине`,
              key: `antine`,
              sort: true,
              filter: {
                type: "text",
              },
            },
            {
              label: `Чиглэл`,
              key: `direction`,
              sort: true,
              filter: {
                type: "text",
              },
            },
            {
              label: `Эгнээ`,
              key: `lane`,
              sort: true,
              filter: {
                type: "text",
              },
            },
            {
              label: `Байршил`,
              key: `coordinates`,
              sort: true,
              filter: {
                type: "text",
              },
              render: ({ _id, name, coordinates }) => {
                return (
                  <div className="font-bold">
                    {`${objectPath.get(coordinates, "lat")} , ${objectPath.get(
                      coordinates,
                      "lng"
                    )}`}
                  </div>
                );
              },
            },
            {
              label: `Хэрэглэгч`,
              key: `description`,
              sort: true,
              filter: {
                type: "text",
              },
              render: ({ _id, createdby }) => {
                const username = objectPath.get(createdby, "username");
                return username;
              },
            },
            {
              label: `HOST ID`,
              key: `host_id`,
              sort: true,
              filter: {
                type: "text",
              },
            },
          ]}
        />
      </div>
    </div>
  );
};

export default index;
