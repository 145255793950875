import {
  Car,
  Element4,
  Information,
  MonitorRecorder,
  I3Dcube,
  User,
  DirectSend,
  TableDocument,
  Driver,
  ShieldSearch,
  CalendarRemove,
  ChemicalGlass,
} from "iconsax-react";

const MENU = [
  {
    label: "Хянах самбар",
    path: "/app/dashboard",
    name: "dashboard",
    icon: <Element4 size={25} />,
  },
  {
    label: "Зөрчил",
    path: "/app/violation",
    name: "violation",
    icon: <Information size={25} />,
  },
  {
    label: "Тээврийн хэрэгсэл",
    path: "/app/vehicle",
    name: `vehicle`,
    icon: <Car size={25} />,
  },
  {
    label: "Төхөөрөмж",
    path: "/app/device",
    name: `device`,
    icon: <MonitorRecorder size={25} />,
  },
  {
    label: "Груп",
    path: "/app/group",
    name: `group`,
    icon: <I3Dcube size={25} />,
  },
  {
    label: "Хэрэглэгч",
    path: "/app/user",
    name: `user`,
    icon: <User size={25} />,
  },
  {
    label: "Илгээсэн",
    path: "/app/sent",
    name: `sent`,
    icon: <DirectSend size={25} />,
  },
  {
    label: "Үйлдлийн түүх",
    path: "/app/log",
    name: `log`,
    icon: <TableDocument size={25} />,
  },
  {
    label: "Эрэн сурвалжлалт",
    path: "/app/asap",
    name: `asap`,
    icon: <ShieldSearch size={25} />,
  },
  {
    label: "Сервис",
    path: "/app/service",
    name: `api`,
    icon: <Driver size={25} />,
  },
  {
    label: "Дугаарын хязгаарлалт",
    path: "/app/limit",
    name: `limit`,
    icon: <CalendarRemove size={25} />,
  },
  {
    label: "Жолоодох эрхгүй",
    path: "/app/drunk",
    name: `drunk`,
    icon: <ChemicalGlass size={25} />,
  },
];

export default MENU;
