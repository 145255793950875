import moment from "moment";
import { mainApi } from "../providers/api";
import { useState, useEffect } from "react";
import objectPath from "object-path";
import { ArrowLeft2, ArrowRight2, Refresh } from "iconsax-react";
import Loader from "../components/Loader";
import DatePicker from "react-datepicker";
import "../datepicker.css";
import Radial from "./radial";
import Swal from "sweetalert2";
import { useApp } from "../providers/app";
import { subDays } from "date-fns";
const checkDate = (date) => {
  if (date > new Date()) {
    Swal.fire(`Анхааруулга`, `Ирээдүйд огноо сонгох боломжгүй`, "error");
    return false;
  } else return true;
};
const Violation = ({ active }) => {
  const [date, setdate] = useState(new Date());
  const [all, setall] = useState();
  const [loading, setloading] = useState(false);
  const [uniq, setuniq] = useState();
  const [loadingUn, setloadingUn] = useState(false);
  const [tab, settab] = useState("day");
  const { user } = useApp();
  const defaultFind =
    user?.role == "statistic" ? { $in: user?.grouplist } : null;
  console.log("🚀 ~ Violation ~ defaultFind:", defaultFind, user?.role);
  useEffect(() => {
    uniqFetch();
  }, []);
  useEffect(() => {
    fetch();
  }, [date]);

  const fetch = async () => {
    setloading(true);

    const response = await mainApi({
      url: `/service/stats`,
      auth: {
        username: "odt",
        password: "odt123456",
      },
      method: `POST`,
      data: {
        date: date,
        device: defaultFind,
      },
    });

    setall(response.data);
    setloading(false);
  };

  const uniqFetch = async () => {
    setloadingUn(true);
    const response = await mainApi({
      url: `/service/distinct`,
      auth: {
        username: "odt",
        password: "odt123456",
      },
      method: `GET`,
    });
    setuniq(response.data);
    setloadingUn(false);
  };
  // .replace(
  //                 /(\d)(?=(\d\d\d)+(?!\d))/g,
  //                 "$1,"
  //               )
  return active == "stat" ? (
    <div className="flex flex-col w-full shadow rounded-lg">
      <div className="flex h-20 bg-tableBackground rounded-t-lg border-b-2 border-odt-border justify-between">
        <div className="flex">
          <div
            onClick={() => settab("day")}
            className={`flex flex-col w-52 justify-center hover:cursor-pointer items-center ${
              tab == "day"
                ? "bg-odt-active rounded-tl-lg text-odt-text"
                : "text-disabled"
            }`}
          >
            <div>Өдөрөөр</div>
            {all && (
              <div>{`Нийт : ${all?.total
                ?.toString()
                ?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}`}</div>
            )}
          </div>
          <div
            onClick={() => settab("graph")}
            className={`flex flex-col w-52 justify-center hover:cursor-pointer items-center ${
              tab == "graph" ? "bg-odt-active text-odt-text" : "text-disabled"
            }`}
          >
            <div>Давхцалгүй</div>
            {uniq && (
              <div>{`Нийт : ${uniq?.total
                ?.toString()
                ?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}`}</div>
            )}
          </div>
        </div>
        <div className="flex justify-center items-center px-8">
          <div className="flex justify-center items-center">
            <div className="flex mx-8 items-center">
              <ArrowLeft2
                onClick={() => {
                  if (checkDate(new Date(moment(date).subtract(1, "days"))))
                    setdate(new Date(moment(date).subtract(1, "days")));
                }}
                className="hover:cursor-pointer"
                size={20}
                color="#6236F5"
              />
              <DatePicker
                className="bg-tableBackground  text-center border-none"
                selected={date}
                dateFormat="yyyy-MM-dd"
                onChange={(date) => {
                  if (checkDate(date)) setdate(date);
                }}
              />
              <ArrowRight2
                onClick={() => {
                  if (checkDate(new Date(moment(date).add(1, "days"))))
                    setdate(new Date(moment(date).add(1, "days")));
                }}
                className="hover:cursor-pointer"
                size={20}
                color="#6236F5"
              />
            </div>
            <div
              className="hover:cursor-pointer"
              onClick={() => {
                tab == "day" ? fetch() : uniqFetch();
              }}
            >
              <Refresh size={20} color="#6236F5" />
            </div>
          </div>
        </div>
      </div>
      {tab == "day" ? (
        <div className=" bg-white rounded-b-lg">
          {loading ? (
            <div className="flex w-full min-h-20 justify-center items-center">
              <Loader size={50} />
            </div>
          ) : (
            <div class="flex overflow-x-auto w-full space-x-4 p-4">
              <div className="min-w-40">
                <div className="flex px-3 py-6 flex-col">
                  <div className="text-black">Нийслэл</div>
                  <div className="text-base leading-5 font-medium text-black mt-1 ">
                    {objectPath
                      .get(all, "ub")
                      ?.toString()
                      ?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                  </div>
                </div>
                <div className="flex px-3 py-6 flex-col">
                  <div className="text-black ">Орон нутаг</div>
                  <div className="text-base leading-5 font-medium text-black mt-1 ">
                    {objectPath
                      .get(all, "country")
                      ?.toString()
                      ?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                  </div>
                </div>
              </div>
              <div className="min-w-40">
                <div className="flex px-3 py-6 flex-col">
                  <div className=" text-black">Зөрчилтэй</div>
                  <div className="text-base leading-5 font-medium text-black mt-1 ">
                    {objectPath
                      .get(all, "violation")
                      ?.toString()
                      ?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                  </div>
                </div>
                <div className="flex px-3 py-6 flex-col">
                  <div className="text-black ">Зөрчилгүй</div>
                  <div className="text-base leading-5 font-medium text-black mt-1 ">
                    {objectPath
                      .get(all, "noviolation")
                      ?.toString()
                      ?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                  </div>
                </div>
              </div>
              <div className="min-w-40">
                <div className="flex px-3 py-6 flex-col">
                  <div className="text-black ">Татвар төлсөн</div>
                  <div className="text-base leading-5 font-medium text-black mt-1  ">
                    {objectPath
                      .get(all, "tax")
                      ?.toString()
                      ?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                  </div>
                </div>
                <div className="flex px-3 py-6 flex-col">
                  <div className="text-black ">Татвар төлөөгүй</div>
                  <div className="text-base leading-5 font-medium text-black mt-1 ">
                    {objectPath
                      .get(all, "notax")
                      ?.toString()
                      ?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                  </div>
                </div>
              </div>
              <div className="min-w-40">
                <div className="flex px-3 py-6 flex-col">
                  <div className="text-black ">Үзлэгт тэнцсэн</div>
                  <div className="text-base leading-5 font-medium text-black mt-1  ">
                    {objectPath
                      .get(all, "inspection")
                      ?.toString()
                      ?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                  </div>
                </div>
                <div className="flex flex-col px-3 py-6">
                  <div className="text-black ">Үзлэгт тэнцээгүй</div>
                  <div className="text-base leading-5 font-medium text-black mt-1  ">
                    {objectPath
                      .get(all, "noinspection")
                      ?.toString()
                      ?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                  </div>
                </div>
              </div>
              <div className="min-w-40">
                <div className="flex px-3 py-6 flex-col">
                  <div className="text-black ">Торгуультай</div>
                  <div className="text-base leading-5 font-medium text-black mt-1  ">
                    {objectPath
                      .get(all, "penalty")
                      ?.toString()
                      ?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                  </div>
                </div>
                <div className="flex px-3 py-6 flex-col">
                  <div className="text-black ">Торгуульгүй</div>
                  <div className="text-base leading-5 font-medium text-black mt-1  ">
                    {objectPath
                      .get(all, "nopenalty")
                      ?.toString()
                      ?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                  </div>
                </div>
              </div>
              <div className="min-w-40">
                <div className="flex px-3 py-6 flex-col">
                  <div className="text-black ">Даатгалтай</div>
                  <div className="text-base leading-5 font-medium text-black mt-1  ">
                    {objectPath
                      .get(all, "insurance")
                      ?.toString()
                      ?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                  </div>
                </div>
                <div className="flex px-3 py-6 flex-col">
                  <div className="text-black ">Даатгалгүй</div>
                  <div className="text-base leading-5 font-medium text-black mt-1  ">
                    {objectPath
                      .get(all, "noinsurance")
                      ?.toString()
                      ?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                  </div>
                </div>
              </div>
              <div className="min-w-40">
                <div className="flex px-3 py-6 flex-col">
                  <div className="text-black ">Д/Хягаарлалттай</div>
                  <div className="text-base leading-5 font-medium text-black mt-1  ">
                    {objectPath
                      .get(all, "limit")
                      ?.toString()
                      ?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                  </div>
                </div>
                <div className="flex px-3 py-6 flex-col">
                  <div className="text-black ">Д/Хягаарлалтгүй</div>
                  <div className="text-base leading-5 font-medium text-black mt-1  ">
                    {objectPath
                      .get(all, "nolimit")
                      ?.toString()
                      ?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                  </div>
                </div>
              </div>
              <div className="min-w-44">
                <div className="flex px-3 py-6 flex-col">
                  <div className="text-black text-start">Жолоодох эрхгүй</div>
                  <div className="text-base leading-5 font-medium text-black mt-1  ">
                    {objectPath
                      .get(all, "limit")
                      ?.toString()
                      ?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                  </div>
                </div>
                <div className="flex px-3 py-6 flex-col">
                  <div className="text-black text-start">Жолоодох эрхтэй</div>
                  <div className="text-base leading-5 font-medium text-black mt-1  ">
                    {objectPath
                      .get(all, "nolimit")
                      ?.toString()
                      ?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                  </div>
                </div>
              </div>
              <div className="min-w-40">
                <div className="flex px-3 py-6 flex-col">
                  <div className="text-black ">АСАП зөрчилтэй</div>
                  <div className="text-base leading-5 font-medium text-black mt-1  ">
                    {objectPath
                      .get(all, "asap")
                      ?.toString()
                      ?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                  </div>
                </div>
                <div className="flex px-3 py-6 flex-col">
                  <div className="text-black ">АСАП зөрчилгүй</div>
                  <div className="text-base leading-5 font-medium text-black mt-1  ">
                    {objectPath
                      .get(all, "noasap")
                      ?.toString()
                      ?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : loadingUn ? (
        <div className="flex w-full min-h-20 justify-center items-center">
          <Loader size={50} />
        </div>
      ) : (
        <div className="flex w-full bg-white rounded-b-lg">
          <div class="flex overflow-x-auto w-full space-x-4 p-4">
            <div className="min-w-40">
              <div className="flex px-3 py-6 flex-col">
                <div className="text-black ">Нийслэл</div>
                <div className="text-base leading-5 font-medium text-black mt-1 ">
                  {objectPath
                    .get(uniq, "ub")
                    ?.toString()
                    ?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                </div>
              </div>
              <div className="flex px-3 py-6 flex-col">
                <div className="text-black ">Орон нутаг</div>
                <div className="text-base leading-5 font-medium text-black mt-1 ">
                  {objectPath
                    .get(uniq, "country")
                    ?.toString()
                    ?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                </div>
              </div>
            </div>
            <div className="min-w-40">
              <div className="flex px-3 py-6 flex-col">
                <div className="text-black ">Зөрчилтэй</div>
                <div className="text-base leading-5 font-medium text-black mt-1 ">
                  {objectPath
                    .get(uniq, "violation")
                    ?.toString()
                    ?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                </div>
              </div>
              <div className="flex px-3 py-6 flex-col">
                <div className="text-black ">Зөрчилгүй</div>
                <div className="text-base leading-5 font-medium text-black mt-1 ">
                  {objectPath
                    .get(uniq, "noviolation")
                    ?.toString()
                    ?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                </div>
              </div>
            </div>
            <div className="min-w-40">
              <div className="flex px-3 py-6 flex-col">
                <div className="text-black ">Татвар төлсөн</div>
                <div className="text-base leading-5 font-medium text-black mt-1 ">
                  {objectPath
                    .get(uniq, "tax")
                    ?.toString()
                    ?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                </div>
              </div>
              <div className="flex px-3 py-6 flex-col">
                <div className="text-black ">Татвар төлөөгүй</div>
                <div className="text-base leading-5 font-medium text-black mt-1 ">
                  {objectPath
                    .get(uniq, "notax")
                    ?.toString()
                    ?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                </div>
              </div>
            </div>
            <div className="min-w-40">
              <div className="flex px-3 py-6 flex-col">
                <div className="text-black ">Үзлэгт тэнцсэн</div>
                <div className="text-base leading-5 font-medium text-black mt-1 ">
                  {objectPath
                    .get(uniq, "inspection")
                    ?.toString()
                    ?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                </div>
              </div>
              <div className="flex flex-col px-3 py-6">
                <div className="text-black ">Үзлэгт тэнцээгүй</div>
                <div className="text-base leading-5 font-medium text-black mt-1 ">
                  {objectPath
                    .get(uniq, "noinspection")
                    ?.toString()
                    ?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                </div>
              </div>
            </div>
            <div className="min-w-40">
              <div className="flex px-3 py-6 flex-col">
                <div className="text-black ">Торгуультай</div>
                <div className="text-base leading-5 font-medium text-black mt-1 ">
                  {objectPath
                    .get(uniq, "penalty")
                    ?.toString()
                    ?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                </div>
              </div>
              <div className="flex px-3 py-6 flex-col">
                <div className="text-black ">Торгуульгүй</div>
                <div className="text-base leading-5 font-medium text-black mt-1 ">
                  {objectPath
                    .get(uniq, "nopenalty")
                    ?.toString()
                    ?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                </div>
              </div>
            </div>
            <div className="min-w-40">
              <div className="flex px-3 py-6 flex-col">
                <div className="text-black ">Даатгалтай</div>
                <div className="text-base leading-5 font-medium text-black mt-1 ">
                  {objectPath
                    .get(uniq, "insurance")
                    ?.toString()
                    ?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                </div>
              </div>
              <div className="flex px-3 py-6 flex-col">
                <div className="text-black ">Даатгалгүй</div>
                <div className="text-base leading-5 font-medium text-black mt-1 ">
                  {objectPath
                    .get(uniq, "noinsurance")
                    ?.toString()
                    ?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                </div>
              </div>
            </div>
            <div className="min-w-40">
              <div className="flex px-3 py-6 flex-col">
                <div className="text-black ">Д/Хягаарлалттай</div>
                <div className="text-base leading-5 font-medium text-black mt-1 ">
                  {objectPath
                    .get(uniq, "limit")
                    ?.toString()
                    ?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                </div>
              </div>
              <div className="flex px-3 py-6 flex-col">
                <div className="text-black ">Д/Хягаарлалтгүй</div>
                <div className="text-base leading-5 font-medium text-black mt-1 ">
                  {objectPath
                    .get(uniq, "nolimit")
                    ?.toString()
                    ?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                </div>
              </div>
            </div>
            <div className="min-w-44">
              <div className="flex px-3 py-6 flex-col">
                <div className="text-black ">Жолоодох эрхгүй</div>
                <div className="text-base leading-5 font-medium text-black mt-1 ">
                  {objectPath
                    .get(uniq, "drunk")
                    ?.toString()
                    ?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                </div>
              </div>
              <div className="flex px-3 py-6 flex-col">
                <div className="text-black ">Жолоодох эрхтэй</div>
                <div className="text-base leading-5 font-medium text-black mt-1 ">
                  {objectPath
                    .get(uniq, "nodrunk")
                    ?.toString()
                    ?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                </div>
              </div>
            </div>
            <div className="min-w-40">
              <div className="flex px-3 py-6 flex-col">
                <div className="text-black ">АСАП зөрчилтэй</div>
                <div className="text-base leading-5 font-medium text-black mt-1 ">
                  {objectPath
                    .get(uniq, "asap")
                    ?.toString()
                    ?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                </div>
              </div>
              <div className="flex px-3 py-6 flex-col">
                <div className="text-black ">АСАП зөрчилгүй</div>
                <div className="text-base leading-5 font-medium text-black mt-1 ">
                  {objectPath
                    .get(uniq, "noasap")
                    ?.toString()
                    ?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  ) : (
    <div className="flex flex-col w-full">
      <div className="flex flex-col w-full shadow rounded-lg">
        <div className="flex h-20  bg-white rounded-t-lg border-b-2 border-odt-border justify-between">
          <div className="flex">
            <div
              className={`flex flex-col w-52 justify-center hover:cursor-pointer items-center`}
            >
              <div className="text-black  text-base font-medium">Өдөрөөр</div>
            </div>
          </div>
          <div className="flex justify-center items-center px-8">
            <div className="flex justify-center items-center">
              <div className="flex mx-8 items-center">
                <ArrowLeft2
                  onClick={() => {
                    if (checkDate(new Date(moment(date).subtract(1, "days"))))
                      setdate(new Date(moment(date).subtract(1, "days")));
                  }}
                  className="hover:cursor-pointer"
                  size={20}
                  color="#6236F5"
                />
                <DatePicker
                  className="bg-white text-center  border-none"
                  selected={date}
                  dateFormat="yyyy-MM-dd"
                  onChange={(date) => {
                    if (checkDate(date)) setdate(date);
                  }}
                />
                <ArrowRight2
                  onClick={() => {
                    if (checkDate(new Date(moment(date).add(1, "days"))))
                      setdate(new Date(moment(date).add(1, "days")));
                  }}
                  className="hover:cursor-pointer"
                  size={20}
                  color="#6236F5"
                />
              </div>
              <div
                className="hover:cursor-pointer"
                onClick={() => {
                  fetch();
                }}
              >
                <Refresh size={20} color="#6236F5" />
              </div>
            </div>
          </div>
        </div>
        {loading ? (
          <div className="flex w-full bg-white min-h-64 justify-center items-center">
            <Loader size={50} />
          </div>
        ) : (
          <div className="flex w-full  rounded-b-lg bg-white min-h-96 items-start">
            <div className="flex w-1/6  h-48 min-h-48">
              <Radial
                first="Нийслэл"
                first_color="#6236F5"
                first_data={objectPath.get(all, "ub")}
                second="Орон нутаг"
                second_color="#FF3A29"
                second_data={objectPath.get(all, "country")}
              />
            </div>
            <div className="flex w-1/6     h-48 min-h-48">
              <Radial
                first="Татвар төлсөн"
                first_data={objectPath.get(all, "tax")}
                first_color="#FF3A29"
                second="Татвар төлөөгүй"
                second_data={objectPath.get(all, "notax")}
                second_color="#FFB200"
              />
            </div>
            <div className="flex w-1/6    h-48 min-h-48">
              <Radial
                first={"Үзлэгт тэнцсэн"}
                first_data={objectPath.get(all, "inspection")}
                first_color="#4339F2"
                second={"Үзлэгт тэнцээгүй"}
                second_data={objectPath.get(all, "noinspection")}
                second_color="#34B53A"
              />
            </div>
            <div className="flex w-1/6    h-48 min-h-48">
              <Radial
                first={"Торгуультай"}
                first_data={objectPath.get(all, "penalty")}
                first_color="#FFB200"
                second={"Торгуульгүй"}
                second_data={objectPath.get(all, "nopenalty")}
                second_color="#6236F5"
              />
            </div>
            <div className="flex w-1/6    h-48 min-h-48">
              <Radial
                first={"Даатгалтай"}
                first_data={objectPath.get(all, "insurance")}
                first_color="#34B53A"
                second={"Даатгалгүй"}
                second_data={objectPath.get(all, "noinsurance")}
                second_color="#4339F2"
              />
            </div>
            <div className="flex w-1/6    h-48 min-h-48">
              <Radial
                first={"Д/Хязгаарлалттай"}
                first_data={objectPath.get(all, "limit")}
                first_color="#34B53A"
                second={"Д/Хязгаарлалтгүй"}
                second_data={objectPath.get(all, "nolimit")}
                second_color="#4339F2"
              />
            </div>
          </div>
        )}
      </div>
      <div className="flex flex-col w-full shadow rounded-lg mt-8">
        <div className="flex h-20 bg-white  rounded-t-lg border-b-2 border-odt-border justify-between items-center">
          <div
            className={`flex flex-col w-52 justify-center hover:cursor-pointer items-center`}
          >
            <div className="text-black  text-base font-medium">Давхцалгүй</div>
          </div>

          <div
            className="hover:cursor-pointer mr-8"
            onClick={() => uniqFetch()}
          >
            <Refresh size={20} color="#6236F5" />
          </div>
        </div>
        {loadingUn ? (
          <div className="flex w-full  bg-white min-h-64 justify-center items-center">
            <Loader size={50} />
          </div>
        ) : (
          <div className="flex w-full flex-wrap  rounded-b-lg bg-white min-h-64 items-start">
            <div className="flex w-1/6   h-48 min-h-48">
              <Radial
                first="Нийслэл"
                first_color="#6236F5"
                first_data={objectPath.get(uniq, "ub")}
                second="Орон нутаг"
                second_color="#FF3A29"
                second_data={objectPath.get(uniq, "country")}
              />
            </div>
            <div className="flex w-1/6    h-48 min-h-48">
              <Radial
                first="Татвар төлсөн"
                first_data={objectPath.get(uniq, "tax")}
                first_color="#FF3A29"
                second="Татвар төлөөгүй"
                second_data={objectPath.get(uniq, "notax")}
                second_color="#FFB200"
              />
            </div>
            <div className="flex w-1/6   h-48 min-h-48">
              <Radial
                first={"Үзлэгт тэнцсэн"}
                first_data={objectPath.get(uniq, "inspection")}
                first_color="#4339F2"
                second={"Үзлэгт тэнцээгүй"}
                second_data={objectPath.get(uniq, "noinspection")}
                second_color="#34B53A"
              />
            </div>
            <div className="flex w-1/6   h-48 min-h-48">
              <Radial
                first={"Торгуультай"}
                first_data={objectPath.get(uniq, "penalty")}
                first_color="#FFB200"
                second={"Торгуульгүй"}
                second_data={objectPath.get(uniq, "nopenalty")}
                second_color="#6236F5"
              />
            </div>
            <div className="flex w-1/6   h-48 min-h-48">
              <Radial
                first={"Даатгалтай"}
                first_data={objectPath.get(uniq, "insurance")}
                first_color="#34B53A"
                second={"Даатгалгүй"}
                second_data={objectPath.get(uniq, "noinsurance")}
                second_color="#4339F2"
              />
            </div>
            <div className="flex w-1/6   h-48 min-h-48">
              <Radial
                first={"Д/Хязгаарлалттай"}
                first_data={objectPath.get(uniq, "limit")}
                first_color="#34B53A"
                second={"Д/Хязгаарлалтгүй"}
                second_data={objectPath.get(uniq, "nolimit")}
                second_color="#4339F2"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Violation;
