import * as Yup from "yup";
import React from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { stringValidation } from "../providers/validations";

import { Select, Text } from "../inputs";
import { Field, Form } from "../template";
import { SHA256 } from "crypto-js";

const AsapForm = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const props = {
    editable: true,
    isDeletable: true,
    model: "drunk",
    text: id ? "Эрэн сурвалжлалт" : "Эрэн сурвалжлалт бүртгэх",
    id: id,
    init: {},
    validationSchema: Yup.object().shape({
      plate: stringValidation(true),
      description: stringValidation(true),
    }),
  };
  const beforeSubmit = (data) => {
    return data;
  };
  return (
    <Form {...props} beforeSubmit={beforeSubmit}>
      {({ submited, disabled, values }) => {
        return (
          <div className="p-5 bg-white grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="flex flex-col gap-4">
              <Field
                name="plate"
                label={`Улсын №`}
                disabled={disabled}
                component={Text}
                type="text"
                className="text-black "
              />
            </div>
            <div className="flex flex-col gap-4">
              <Field
                name="description"
                label={`Тайлбар`}
                disabled={disabled}
                component={Text}
                type="text"
                textarea={true}
                className="text-black "
              />
            </div>
          </div>
        );
      }}
    </Form>
  );
};

export default AsapForm;
