import objectPath from "object-path";
const Row = ({ name, no_ub, ub, no_country, country }) => {
  return (
    <div className="flex w-full border-b border-odt-border">
      <div className="flex w-1/3 text-black  justify-center items-center px-3 py-4">
        {name}
      </div>
      <div className="flex w-1/3 justify-center items-center">
        <div className="w-1/2 text-black  text-center font-medium text-base">
          {no_ub
            ? no_ub?.toString()?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
            : 0}
        </div>
        <div className="w-1/2 text-black  text-center font-medium text-base">
          {ub ? ub?.toString()?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,") : 0}
        </div>
      </div>
      <div className="flex w-1/3 justify-center items-center">
        <div className="w-1/2 text-black  text-center font-medium text-base">
          {no_country
            ? no_country?.toString()?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
            : 0}
        </div>
        <div className="w-1/2 text-black  text-center font-medium text-base">
          {country
            ? country?.toString()?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
            : 0}
        </div>
      </div>
    </div>
  );
};
const Table = ({ data }) => {
  console.log("🚀 ~ Table ~ data:", data);
  const COUNTRY =
    objectPath.get(data, "0.location") == "COUNTRY"
      ? objectPath.get(data, "0")
      : objectPath.get(data, "1");
  const UB =
    objectPath.get(data, "0.location") == "UB"
      ? objectPath.get(data, "0")
      : objectPath.get(data, "1");
  const total =
    objectPath.get(data, "0.total") + objectPath.get(data, "1.total");

  return (
    <div className="flex flex-col w-full mt-8">
      <div className="flex w-full border-b-2 border-odt-border">
        <div className="flex flex-col w-1/3 justify-center items-center">
          <div className="h-10 text-center text-black  font-medium text-base">
            Нийт
          </div>
          <div className="h-10 text-center font-semibold text-black  text-base">
            {isNaN(total)
              ? 0
              : total?.toString()?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}
          </div>
        </div>
        <div className="flex flex-col w-1/3 justify-center items-center">
          <div className="h-10 font-semibold text-black  text-base">
            Нийслэл
          </div>
          <div className="flex h-10 w-full">
            <div className="w-1/2 text-center text-black  font-medium text-base">
              Зөрчилтэй
            </div>
            <div className="w-1/2 text-center text-black  font-medium text-base">
              Зөрчилгүй
            </div>
          </div>
        </div>
        <div className="flex flex-col w-1/3 justify-center items-center">
          <div className="h-10 font-semibold text-black  text-base">
            Орон нутаг
          </div>
          <div className="flex h-10 w-full">
            <div className="w-1/2 text-center text-black  font-medium text-base">
              Зөрчилтэй
            </div>
            <div className="w-1/2 text-center text-black  font-medium text-base">
              Зөрчилгүй
            </div>
          </div>
        </div>
      </div>
      <Row
        name={"Татвар"}
        no_ub={objectPath.get(UB, "notax")}
        ub={objectPath.get(UB, "tax")}
        country={objectPath.get(COUNTRY, "notax")}
        no_country={objectPath.get(COUNTRY, "tax")}
      />
      <Row
        name={"Даатгал"}
        no_ub={objectPath.get(UB, "noinsurance")}
        ub={objectPath.get(UB, "insurance")}
        country={objectPath.get(COUNTRY, "noinsurance")}
        no_country={objectPath.get(COUNTRY, "insurance")}
      />
      <Row
        name={"Оншилгоо"}
        no_ub={objectPath.get(UB, "noinspection")}
        ub={objectPath.get(UB, "inspection")}
        country={objectPath.get(COUNTRY, "noinspection")}
        no_country={objectPath.get(COUNTRY, "inspection")}
      />
      <Row
        name={"Торгууль"}
        no_ub={objectPath.get(UB, "nopenalty")}
        ub={objectPath.get(UB, "penalty")}
        country={objectPath.get(COUNTRY, "nopenalty")}
        no_country={objectPath.get(COUNTRY, "penalty")}
      />
      <Row
        name={"Эрэн сурвалжлалт"}
        no_ub={objectPath.get(UB, "asap")}
        ub={objectPath.get(UB, "noasap")}
        country={objectPath.get(COUNTRY, "noasap")}
        no_country={objectPath.get(COUNTRY, "asap")}
      />
      <Row
        name={"Жолоодох эрхгүй"}
        ub={objectPath.get(UB, "nodrunk")}
        no_ub={objectPath.get(UB, "drunk")}
        country={objectPath.get(COUNTRY, "nodrunk")}
        no_country={objectPath.get(COUNTRY, "drunk")}
      />
      <Row
        name={"Дугаарын хязгаарлалт"}
        ub={objectPath.get(UB, "nolimit")}
        no_ub={objectPath.get(UB, "limit")}
        country={objectPath.get(COUNTRY, "nolimit")}
        no_country={objectPath.get(COUNTRY, "limit")}
      />
      <Row
        name={"Зөрчилтэй Т/Х"}
        ub={objectPath.get(UB, "violation")}
        no_ub={objectPath.get(UB, "noviolation")}
        country={objectPath.get(COUNTRY, "violation")}
        no_country={objectPath.get(COUNTRY, "noviolation")}
      />
    </div>
  );
};
export default Table;
